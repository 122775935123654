#item-search, #content-search, #skill-content {
  h3.results_for {
    margin-bottom: 1em;
    color: $feldgrau;
    span {
      color: $oxford-blue;
    }
  }

  .item-search-filter-wrapper {
    display: flex;
    flex-direction: row;
    margin-top: 40px;

    .item-filter {
      display: inline-block;
      margin-right: 2em;

      .filter-button {
        background-color: $white;
        box-shadow: 0 0 5px rgba($cool-grey, .5);
        border-radius: 10px;
        min-width: 180px;
        height: 40px;
        text-align: center;
        font-weight: lighter;
        padding: 12px;
        font-size: small;
        cursor: pointer;

        &.active {
          font-weight: bold;
        }
      }

      &--modal-group {
        max-height: 300px;
        overflow: scroll;
      }
      .filter-body {
        position: absolute;
        margin-top: 10px;
        z-index: 10;
        background: rgb(255, 255, 255);
        border: 0.5px solid $border-default;
        border-radius: 12px;
        box-shadow: 0 0 5px rgba($cool-grey, .5);
        white-space: normal;
        min-width: 200px;

        &--wrapper {
          overflow: auto;
          overflow-x: hidden;
          padding: 20px 20px 0 20px;
          max-height: 300px;
        }

        &--action-buttons {
          position: relative;
          padding: 10px 15px;
          border-top: 3px solid $border-default;

          &--cancel {
            color: $cool-grey;
            margin-top: 3px;
            float: left;
          }

          a.btn-primary {
            float: right;
            border-radius: 0;
            padding: 3px 10px;
            margin-bottom: 10px;
            font-size: 14px;
          }
        }

        .radio {
          margin-top: 0;
          margin-bottom: 16px;

          label {
            color: $cool-grey;
          }

          .ic {
            vertical-align: middle;
          }
        }
      }

      &--value-boxes {
        display: flex;
        flex-direction: row;
        justify-content: center;

        &--box {
          border-radius: 8px;
          border: 1px solid $border-default;
          width: 120px;
          height: 60px;
          margin: 10px;
          padding: 0 5px;

          &--value {
            font-weight: bold;
            font-size: 18px;
            text-align: center;
          }
        }
      }
    }

    .noUi-target {
      margin: 40px 0;
    }
  }
}
