$white:           #fff;
$dew:             #F5FFFE;
$ghost-white:     #f5faff;
$bubbles:         #e5f3ff;
$blizzard-blue:   #b3dcff;
$light-sky-blue:  #80c4ff;
$maya-blue:       #5cb4ff;
$dodger-blue:     #33a1ff;
$true-blue:       #007be6;
$cobalt:          #00529a;
$medium-teal-blue: #0060b3;
$dark-midnight-blue: #003766;
$oxford-blue:     #00294d;
$bubbles-celeste: #e5fffc;
$light-celeste:   #c2fff9;
$celeste:         #a3fff6;
$electric-blue:   #8ffff4;
$turquoise:       #3df5e3;
$bright-turquoise: #00e6cf;
$teal-light:      #59c3b7;
$persian-green:   #00b3a1;
$spectra-green:   #009a8a;
$dark-persian-green:  #018073;
$teal:            #008a7c;
$teal-green:      #00665c;
$deep-jungle-green: #004d45;
$white-smoke:     #f3f5f7;
$cultured:        #f5f5f5;
$platinum:        #e0e6eb;
$lavendar-gray:   #c3ccd5;
$pastel-blue:     #b8c2cc;
$cadet-grey:      #99a5b2;
$cool-grey:       #8795a1;
$slate-grey:      #6e8191;
$dark-electric-blue: #606f7b;
$active-admin-grey: #5E6469;
$menu-pip-grey:   #4A5764;
$feldgrau:        #4a5864;
$jet:             #333333;
$charcoal:        #3c4853;
$old-lace:        #fff3e5;
$blanched-almond: #ffe4cc;
$deep-peach:      #ffd1a8;
$topaz:           #ffbb80;
$neon-carrot:     #ffa04c;
$deep-saffron:    #ff9233;
$pumpkin:         #ff851a;
$fulvous:         #e66b00;
$ginger:          #b35300;
$chocolate:       #803b00;
$blue-chalk:      #ebe5ff;
$pale-lavendar:   #d7ccff;
$bright-ube:      #bda8ff;
$medium-slate-blue: #8861ff;
$lavendar-indigo: #7944ff;
$han-purple-light: #6417ff;
$electric-indigo-light: #5c00fa;
$han-purple:      #5600cf;
$electric-indigo: #6100f1;
$indigo:          #400080;
$green-almost-white:#fbfef9;
$hint-of-green:   #e5fff0;
$pale-green:      #ccffe1;
$magic-mint:      #99ffc3;
$medium-aquamarine: #66ffa5;
$guppie-green-light: #33ff87;
$guppie-green:    #00ff6a;
$newer-green-text:#79ba75;
$malachite:       #00cc54;
$north-texas-green: #00993f;
$cadmium-green:   #00662a;
$forest-green:    #004d20;
$pippin:          #ffe5e5;
$bubble-gum:      #ffcccc;
$light-salmon-pink: #ff9999;
$pastel-red:      #ff6666;
$deep-carmine-pink: #ff3333;
$red:             #ff0000;
$boston-university-red: #cc0000;
$ou-crimson-red:  #990000;
$rose-wood:       #660000;
$bulgarian-rose:  #4d0000;
$half-colonial-white: #fdf8ce;
$lemon-chiffon:   #fff7cc;
$flavescent:      #ffec99;
$jonquil:         #ffe066;
$sunglow:         #ffd333;
$amber:           #ffbf00;
$gamboge:         #e6ac00;
$dark-goldenrod:  #b38000;
$golden-brown:    #996b00;
$ginger:          #a56e00;
$black:           #000;
$border-default:  $pastel-blue;
$header-blue:     $oxford-blue;
$shadow-default:  $cool-grey;
$accent-tertiary: $fulvous;
$gpt-lightgrey: #F4F4F4;
$gpt-darkgrey: #D7D7D7;

$go1-color: rgb(17,73,73);
$selectize-border-color: #cccccc;
