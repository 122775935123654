$search-input-border: $mid-gray;

/* Block
   ========================================================================= */
.search-input {
  position: relative;
}

/* Elements
   ========================================================================= */
.search-input__input {
  border-radius: 4px;
  border: 1px solid $search-input-border;
  padding: 12px 15px 12px 40px;
  width: 100%;
  @include form-control-focus($input-border-focus);
}

.search-input__input--big {
  padding: 15px 20px 15px 45px;
  height: 42px;
}

.search-input__icon {
  color: $dark-gray;
  display: block;
  height: 10px;
  left: 15px;
  pointer-events: none;
  position: absolute;
  top: 12px;
  width: 10px;
  font-size: 1.2em;

  &:after {
    content: $ic-search;
    font-family: ic;
  }
}

.search-input--wide {
  min-width: 320px;

  @media #{$mobile} {
    width: 100%;
  }
}

/* Element modifiers
   ========================================================================= */
.search-input--quicksearch {
  padding: 5px 10px;

  .search-input__input {
    padding: 8px 0 8px 30px;
  }

  .search-input__icon {
    left: 20px;
    top: 13px;
  }
}

.search-input__input--dropdown {
  border-radius: 0;
  min-width: 300px;
  padding: 8px 15px 8px 40px;
}

.search-input__icon--dropdown {
  top: 8px;
}

.search-input__input--inside-tabs {
  height: 36px;
  margin-top: 3px;
}

.search-input__icon--inside-tabs {
  top: 10px;
}

.search-input__input--tile-dropdown {
  border-radius: 0;
  min-width: 254px;
  padding: 8px 15px 8px 40px;
}

.search-input__icon--tile-dropdown {
  top: 8px;
}

.search-input__icon--ready,
.search-input__icon--clear {
  color: $teal-light;
  pointer-events: all;

  &::after {
    content: $ic-error-cross;
    font-size: 16px;
  }
}

.search-input__icon--inside-inline-form {
  left: 6px;
  top: 8px;

  &::after {
    font-size: 12px;
  }
}

.search-input__input--inside-inline-form {
  padding-left: 20px;
}

.search-input__clickable-icon {
  color: $teal-light;
  display: block;
  height: 10px;
  left: 15px;
  position: absolute;
  top: 12px;
  width: 10px;
  cursor: pointer;

  &:after {
    content: $ic-error-cross;
    font-family: ic;
  }
}

.search-input__clickable-icon--clear {
  left: auto;
  right: 15px;

  &:after {
    content: $ic-error-cross;
  }
}

.search-input--width-limited {
  max-width: 500px;
}
