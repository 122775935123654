// When adding new fonts from Icomoon, export SASS variables, and replace all the definitions here:
$icomoon-font-family: 'ic' !default;
$icomoon-font-path: 'fonts' !default;

$ic-bars-progress-regular: unquote('"\\ea5d"');
$ic-file-shield-regular: unquote('"\\ea5c"');
$ic-file-shield-solid: unquote('"\\ea5b"');
$ic-t-analytics-graph-line-statistics: unquote('"\\ea59"');
$ic-t-bookmarks-reading-book: unquote('"\\ea5a"');
$ic-in-progress: unquote('"\\ea58"');
$ic-t-shared-time-management: unquote('"\\ea57"');
$ic-sparkle: unquote('"\\ea56"');
$ic-shrink: unquote('"\\ea54"');
$ic-expand: unquote('"\\ea55"');
$ic-flask: unquote('"\\ea53"');
$ic-drag-indicator: unquote('"\\ea52"');
$ic-b1-close-remove-exit: unquote('"\\ea51"');
$ic-t-like-thumb-up-vote: unquote('"\\ea4f"');
$ic-t-like-thumb-up-vote-filled: unquote('"\\ea50"');
$ic-t-line-structure-relations-hierarchy: unquote('"\\ea4e"');
$ic-t-support-help-talk-call: unquote('"\\ea47"');
$ic-t-construction-worker-site-helmet-safety: unquote('"\\ea48"');
$ic-t-gamepad-video-game-playing: unquote('"\\ea49"');
$ic-b2-bank-building: unquote('"\\ea4a"');
$ic-t-information-desk-support-help: unquote('"\\ea4b"');
$ic-t-information-office-desk-support-help: unquote('"\\ea4c"');
$ic-t-business-idea: unquote('"\\ea4d"');
$ic-t-light-bulb-idea: unquote('"\\ea46"');
$ic-b1-file-import: unquote('"\\ea45"');
$ic-t-computer-laptop-working-user: unquote('"\\ea44"');
$ic-b1-certificate: unquote('"\\ea43"');
$ic-t-computer-screen-settings-preferences: unquote('"\\ea42"');
$ic-b3-open: unquote('"\\ea40"');
$ic-t-detective-criminal-sherlock-investigation: unquote('"\\ea41"');
$ic-auth0: unquote('"\\ea3f"');
$ic-t-computer-scren-maximize-size: unquote('"\\ea3c"');
$ic-b2-maximize-window-external-link: unquote('"\\ea3d"');
$ic-b2-maximize-window-pop-out: unquote('"\\ea3e"');
$ic-t-seo-marketing-ideas-strategy-playbook: unquote('"\\ea39"');
$ic-t-text-numbered-list: unquote('"\\ea3a"');
$ic-t-click-rate-conversion-improvement: unquote('"\\ea3b"');
$ic-t-cloud-connected-connectivity-shared-virtual: unquote('"\\ea37"');
$ic-t-code-html: unquote('"\\ea38"');
$ic-t-check-ok-yes-no: unquote('"\\ea35"');
$ic-t-checkboxes-lines-check: unquote('"\\ea36"');
$ic-t-seo-marketing-content-strategy-puzzles: unquote('"\\ea34"');
$ic-t-computer-laptop-website-url-www: unquote('"\\ea33"');
$ic-b1-user-add-new: unquote('"\\ea32"');
$ic-t-calendar-month-day-planner-events: unquote('"\\ea30"');
$ic-b1-list-checkbox: unquote('"\\ea31"');
$ic-b1-bookmark: unquote('"\\ea2d"');
$ic-b2-media-play: unquote('"\\ea2e"');
$ic-t-bookmark-tag: unquote('"\\ea2f"');
$ic-arrows: unquote('"\\ea2c"');
$ic-microsoft-teams: unquote('"\\ea2a"');
$ic-zoom: unquote('"\\ea2b"');
$ic-t-screen-text: unquote('"\\ea29"');
$ic-t-analytics-presentation-statistics-graph: unquote('"\\ea28"');
$ic-t-kpi-dashboard: unquote('"\\ea26"');
$ic-t-kpi-dashboard-monitor: unquote('"\\ea27"');
$ic-t-users-people-group-couple: unquote('"\\ea1d"');
$ic-t-archery-target-shooting: unquote('"\\ea1e"');
$ic-t-business-stats-report-meeting-annual: unquote('"\\ea1f"');
$ic-t-group-people-employees: unquote('"\\ea20"');
$ic-t-screen-text-images-presentation: unquote('"\\ea21"');
$ic-t-world-earth-worldwide-location-travel: unquote('"\\ea22"');
$ic-b3-color-paint-brush: unquote('"\\ea23"');
$ic-t-fingerprint-scan-security-access: unquote('"\\ea24"');
$ic-t-user-profile-card: unquote('"\\ea25"');
$ic-b3-arrows-switch: unquote('"\\ea1b"');
$ic-t-minus-delete-remove: unquote('"\\ea1c"');
$ic-t-bugs-search-report-discover: unquote('"\\ea17"');
$ic-t-notification-news-marketing-announcement: unquote('"\\ea18"');
$ic-t-rocket-space-nasa: unquote('"\\ea19"');
$ic-t-diamond-prize-award-jewellery-ring: unquote('"\\ea1a"');
$ic-t-about-question-faq-help: unquote('"\\ea16"');
$ic-t-email-mail-post-card: unquote('"\\ea14"');
$ic-t-email-mail-post-card-sent-successful: unquote('"\\ea15"');
$ic-t-ringer-silent-sleeping: unquote('"\\ea13"');
$ic-b3-social-connections-hierarchy-relations-structure: unquote('"\\ea0b"');
$ic-t-home-flat: unquote('"\\ea0c"');
$ic-t-users-profile-group-two: unquote('"\\ea0d"');
$ic-t-business-suitcase: unquote('"\\ea0e"');
$ic-t-world-earth-worldwide-international-language: unquote('"\\ea0f"');
$ic-t-time-watch-clock-wall: unquote('"\\ea10"');
$ic-t-computer-mobile-sync: unquote('"\\ea11"');
$ic-t-factory-work: unquote('"\\ea12"');
$ic-t-door-logout-exit: unquote('"\\ea05"');
$ic-b1-email-delivery-send: unquote('"\\ea06"');
$ic-t-module-connection-plugin: unquote('"\\ea07"');
$ic-t-wall-picture-image-photo: unquote('"\\ea08"');
$ic-t-residental-city-company-skyscraper: unquote('"\\ea09"');
$ic-t-window-text-msg-box: unquote('"\\ea0a"');
$ic-t-star-favorite-rating: unquote('"\\ea04"');
$ic-b2-users-group-people: unquote('"\\ea03"');
$ic-t-user-profile-add-new: unquote('"\\ea02"');
$ic-t-idea-thinking-study-startup: unquote('"\\ea01"');
$ic-t-ecology-earth-polution-efficiency-green-energy: unquote('"\\ea00"');
$ic-b2-personal-id-photo: unquote('"\\e9ff"');
$ic-t-movie-recording-play-director-cut: unquote('"\\e9fb"');
$ic-b2-personal-profile-id: unquote('"\\e9fc"');
$ic-t-window-text-images: unquote('"\\e9fd"');
$ic-t-window-application-cursor: unquote('"\\e9fe"');
$ic-b2-bookmark: unquote('"\\e9f6"');
$ic-b1-graph-bars-chart-rise: unquote('"\\e9f7"');
$ic-t-path-travel-road: unquote('"\\e9f8"');
$ic-t-brain-thinking-mind-knowledge: unquote('"\\e9f9"');
$ic-t-nature-green-sprout-plant: unquote('"\\e9fa"');
$ic-t-folder: unquote('"\\e900"');
$ic-t-folder-upload: unquote('"\\e901"');
$ic-t-folder-files-documents: unquote('"\\e902"');
$ic-t-file-document-error-unavailable: unquote('"\\e903"');
$ic-t-file-document-approved-valid: unquote('"\\e904"');
$ic-b2-file-security-password-locked: unquote('"\\e905"');
$ic-youtube: unquote('"\\e906"');
$ic-windows: unquote('"\\e907"');
$ic-website: unquote('"\\e908"');
$ic-webcam: unquote('"\\e909"');
$ic-wake-up: unquote('"\\e90a"');
$ic-video-play: unquote('"\\e90b"');
$ic-video-play-movie: unquote('"\\e90c"');
$ic-two-men: unquote('"\\e90d"');
$ic-twitter: unquote('"\\e90e"');
$ic-trash: unquote('"\\e90f"');
$ic-thumb-tack: unquote('"\\e910"');
$ic-t-write-pencil-new-edit: unquote('"\\e911"');
$ic-t-warning-triangle: unquote('"\\e912"');
$ic-t-video-camera: unquote('"\\e913"');
$ic-t-users-man-woman: unquote('"\\e914"');
$ic-t-users-group: unquote('"\\e915"');
$ic-t-user-woman: unquote('"\\e916"');
$ic-t-user-man: unquote('"\\e917"');
$ic-t-user-location-position: unquote('"\\e918"');
$ic-t-user-key: unquote('"\\e919"');
$ic-t-user-connected: unquote('"\\e91a"');
$ic-t-user-check: unquote('"\\e91b"');
$ic-t-unlink-chain: unquote('"\\e91c"');
$ic-t-ticket: unquote('"\\e91d"');
$ic-t-thinking-puzzle-idea: unquote('"\\e91e"');
$ic-t-text-bullets-list: unquote('"\\e91f"');
$ic-t-target-goal-growth-success: unquote('"\\e920"');
$ic-t-tags: unquote('"\\e921"');
$ic-t-tag: unquote('"\\e922"');
$ic-t-study-graduation-university: unquote('"\\e923"');
$ic-t-stopwatch-timer-running-time: unquote('"\\e924"');
$ic-t-settings-tools-configuration-preferences: unquote('"\\e925"');
$ic-t-settings-manage: unquote('"\\e926"');
$ic-t-send-post-paper-plane: unquote('"\\e927"');
$ic-t-security-lock: unquote('"\\e928"');
$ic-t-security-lock-unlocked: unquote('"\\e929"');
$ic-t-security-lock-protected: unquote('"\\e92a"');
$ic-t-security-lock-key: unquote('"\\e92b"');
$ic-t-search-circle: unquote('"\\e92c"');
$ic-t-scatter-structure: unquote('"\\e92d"');
$ic-t-rotate-sync: unquote('"\\e92e"');
$ic-t-reporter: unquote('"\\e92f"');
$ic-t-repeat-loop-again-back: unquote('"\\e930"');
$ic-t-real-time-clock: unquote('"\\e931"');
$ic-t-reading: unquote('"\\e932"');
$ic-t-presentation-powerpoint-keynote-meeting: unquote('"\\e933"');
$ic-t-playbook-strategy-tactics-path: unquote('"\\e934"');
$ic-t-pie-chart: unquote('"\\e935"');
$ic-t-pie-chart-o: unquote('"\\e936"');
$ic-t-owl: unquote('"\\e937"');
$ic-t-ok-successful-check: unquote('"\\e938"');
$ic-t-microphone: unquote('"\\e939"');
$ic-t-medal-star: unquote('"\\e93a"');
$ic-t-map-path: unquote('"\\e93b"');
$ic-t-map-marker: unquote('"\\e93c"');
$ic-t-mail-send: unquote('"\\e93d"');
$ic-t-location-marker: unquote('"\\e93e"');
$ic-t-linear-structure: unquote('"\\e93f"');
$ic-t-line-structure-hierarchy: unquote('"\\e940"');
$ic-t-like-heart-favorite-rating-love: unquote('"\\e941"');
$ic-t-lab: unquote('"\\e942"');
$ic-t-key-lock-briefcase: unquote('"\\e943"');
$ic-t-input-text: unquote('"\\e944"');
$ic-t-info-circle: unquote('"\\e945"');
$ic-t-image-photo: unquote('"\\e946"');
$ic-t-hourglass: unquote('"\\e947"');
$ic-t-hierarchy-users-employees-permissions: unquote('"\\e948"');
$ic-t-heart-vitals-pulse-rate-health: unquote('"\\e949"');
$ic-t-heart-pulse-rate-health: unquote('"\\e94a"');
$ic-t-heart-o: unquote('"\\e94b"');
$ic-t-headphones: unquote('"\\e94c"');
$ic-t-handshake: unquote('"\\e94d"');
$ic-t-group-users-circle: unquote('"\\e94e"');
$ic-t-grid-view: unquote('"\\e94f"');
$ic-t-globe-switch: unquote('"\\e950"');
$ic-t-gear-cog: unquote('"\\e951"');
$ic-t-floppy-save: unquote('"\\e952"');
$ic-t-filter: unquote('"\\e953"');
$ic-t-eye-visible: unquote('"\\e954"');
$ic-t-eye-hidden: unquote('"\\e955"');
$ic-t-external-link: unquote('"\\e956"');
$ic-t-error-stop: unquote('"\\e957"');
$ic-t-error-cross: unquote('"\\e958"');
$ic-t-envelope: unquote('"\\e959"');
$ic-t-edit-pencil: unquote('"\\e95a"');
$ic-t-dropdown-menu-select-option-form: unquote('"\\e95b"');
$ic-t-cross-circle: unquote('"\\e95c"');
$ic-t-cloud-upload: unquote('"\\e95d"');
$ic-t-clock: unquote('"\\e95e"');
$ic-t-chat-discussion: unquote('"\\e95f"');
$ic-t-chat-discussion-yes-no: unquote('"\\e960"');
$ic-t-card-flyer: unquote('"\\e961"');
$ic-t-calendar-week: unquote('"\\e962"');
$ic-t-calendar-date: unquote('"\\e963"');
$ic-t-bullets-list: unquote('"\\e964"');
$ic-t-bullets-list-radiobuttons: unquote('"\\e965"');
$ic-t-bullet-list-view: unquote('"\\e966"');
$ic-t-bell: unquote('"\\e967"');
$ic-t-bar-chart: unquote('"\\e968"');
$ic-t-bar-chart-value: unquote('"\\e969"');
$ic-t-bar-chart-growth: unquote('"\\e96a"');
$ic-t-atom: unquote('"\\e96b"');
$ic-t-arrows-play-shuffle: unquote('"\\e96c"');
$ic-t-arrows-fast-forward: unquote('"\\e96d"');
$ic-t-arrow-right-next: unquote('"\\e96e"');
$ic-t-arrow-next-right: unquote('"\\e96f"');
$ic-t-arrow-back-left: unquote('"\\e970"');
$ic-t-anti-virus-protection-shield: unquote('"\\e971"');
$ic-t-alarm-clock: unquote('"\\e972"');
$ic-t-add-circle: unquote('"\\e973"');
$ic-study-graduation-university: unquote('"\\e974"');
$ic-star: unquote('"\\e975"');
$ic-star-o: unquote('"\\e976"');
$ic-star-half: unquote('"\\e977"');
$ic-square-o: unquote('"\\e978"');
$ic-speakers-sound: unquote('"\\e979"');
$ic-sort: unquote('"\\e97a"');
$ic-slack: unquote('"\\e97b"');
$ic-share: unquote('"\\e97c"');
$ic-search: unquote('"\\e97d"');
$ic-search-circle: unquote('"\\e97e"');
$ic-rotate-right: unquote('"\\e97f"');
$ic-retweet: unquote('"\\e980"');
$ic-reply: unquote('"\\e981"');
$ic-repeat: unquote('"\\e982"');
$ic-refresh: unquote('"\\e983"');
$ic-random: unquote('"\\e984"');
$ic-quote-right: unquote('"\\e985"');
$ic-quote-left: unquote('"\\e986"');
$ic-puzzle: unquote('"\\e987"');
$ic-presenting: unquote('"\\e988"');
$ic-presentation-keynote: unquote('"\\e989"');
$ic-pound-circle: unquote('"\\e98a"');
$ic-plus: unquote('"\\e98b"');
$ic-pinterest: unquote('"\\e98c"');
$ic-pencil: unquote('"\\e98d"');
$ic-pencil-ruller: unquote('"\\e98e"');
$ic-paperclip: unquote('"\\e98f"');
$ic-newspaper: unquote('"\\e990"');
$ic-newspaper-sloping: unquote('"\\e991"');
$ic-minus: unquote('"\\e992"');
$ic-minus-circle: unquote('"\\e993"');
$ic-long-arrow-right: unquote('"\\e994"');
$ic-long-arrow-left: unquote('"\\e995"');
$ic-linkedin: unquote('"\\e996"');
$ic-link: unquote('"\\e997"');
$ic-learnamp: unquote('"\\e998"');
$ic-laptop: unquote('"\\e999"');
$ic-lab-experiment: unquote('"\\e99a"');
$ic-info: unquote('"\\e99b"');
$ic-image-photo: unquote('"\\e99c"');
$ic-help-question: unquote('"\\e99d"');
$ic-heart: unquote('"\\e99e"');
$ic-heart-o: unquote('"\\e99f"');
$ic-google: unquote('"\\e9a0"');
$ic-globe: unquote('"\\e9a1"');
$ic-github: unquote('"\\e9a2"');
$ic-floppy-save: unquote('"\\e9a3"');
$ic-flag: unquote('"\\e9a4"');
$ic-flag-checkered: unquote('"\\e9a5"');
$ic-file-word-o: unquote('"\\e9a6"');
$ic-file-video-o: unquote('"\\e9a7"');
$ic-file-text-o: unquote('"\\e9a8"');
$ic-file-powerpoint-o: unquote('"\\e9a9"');
$ic-file-pdf-o: unquote('"\\e9aa"');
$ic-file-image-o: unquote('"\\e9ab"');
$ic-file-excel-o: unquote('"\\e9ac"');
$ic-file-audio-o: unquote('"\\e9ad"');
$ic-file-archive-o: unquote('"\\e9ae"');
$ic-facebook: unquote('"\\e9af"');
$ic-external-link: unquote('"\\e9b0"');
$ic-exclamation: unquote('"\\e9b1"');
$ic-exclamation-circle: unquote('"\\e9b2"');
$ic-error-cross: unquote('"\\e9b3"');
$ic-envelope: unquote('"\\e9b4"');
$ic-envelope-o: unquote('"\\e9b5"');
$ic-ellipsis-v: unquote('"\\e9b6"');
$ic-ellipsis-h: unquote('"\\e9b7"');
$ic-drivers-license: unquote('"\\e9b8"');
$ic-drivers-license-o: unquote('"\\e9b9"');
$ic-dribbble: unquote('"\\e9ba"');
$ic-download: unquote('"\\e9bb"');
$ic-dollar-circle: unquote('"\\e9bc"');
$ic-document-text: unquote('"\\e9bd"');
$ic-comment-chat: unquote('"\\e9be"');
$ic-cloud-upload: unquote('"\\e9bf"');
$ic-clipboard: unquote('"\\e9c0"');
$ic-circle: unquote('"\\e9c1"');
$ic-circle-o: unquote('"\\e9c2"');
$ic-circle-o-notch: unquote('"\\e9c3"');
$ic-chevron-up: unquote('"\\e9c4"');
$ic-chevron-right: unquote('"\\e9c5"');
$ic-chevron-left: unquote('"\\e9c6"');
$ic-chevron-down: unquote('"\\e9c7"');
$ic-checkbox-yes: unquote('"\\e9c8"');
$ic-check: unquote('"\\e9c9"');
$ic-check-square-o: unquote('"\\e9ca"');
$ic-check-circle: unquote('"\\e9cb"');
$ic-check-circle-o: unquote('"\\e9cc"');
$ic-caret-up: unquote('"\\e9cd"');
$ic-caret-right: unquote('"\\e9ce"');
$ic-caret-left: unquote('"\\e9cf"');
$ic-caret-down: unquote('"\\e9d0"');
$ic-calendar: unquote('"\\e9d1"');
$ic-calendar-wall: unquote('"\\e9d2"');
$ic-calendar-date: unquote('"\\e9d3"');
$ic-bullhorn-megaphone: unquote('"\\e9d4"');
$ic-brain-question: unquote('"\\e9d5"');
$ic-brain-face: unquote('"\\e9d6"');
$ic-book: unquote('"\\e9d7"');
$ic-bell: unquote('"\\e9d8"');
$ic-bars: unquote('"\\e9d9"');
$ic-bar-chart: unquote('"\\e9da"');
$ic-b2-to-do-list-clipboard: unquote('"\\e9db"');
$ic-b2-shield-security-virus: unquote('"\\e9dc"');
$ic-b2-like-heart: unquote('"\\e9dd"');
$ic-b2-heart-like-remove: unquote('"\\e9de"');
$ic-b2-heart-like-add: unquote('"\\e9df"');
$ic-b2-grid-view: unquote('"\\e9e0"');
$ic-b2-filter: unquote('"\\e9e1"');
$ic-b2-checkboxe-list-to-do: unquote('"\\e9e2"');
$ic-b2-checkbox-list-to-do: unquote('"\\e9e3"');
$ic-b2-bullets-list: unquote('"\\e9e4"');
$ic-b2-arrow-right: unquote('"\\e9e5"');
$ic-b1-list-radiobutton: unquote('"\\e9e6"');
$ic-b1-alert-attention: unquote('"\\e9e7"');
$ic-article: unquote('"\\e9e8"');
$ic-arrow-up: unquote('"\\e9e9"');
$ic-arrow-right: unquote('"\\e9ea"');
$ic-arrow-left: unquote('"\\e9eb"');
$ic-arrow-down: unquote('"\\e9ec"');
$ic-angle-up: unquote('"\\e9ed"');
$ic-angle-right: unquote('"\\e9ee"');
$ic-angle-left: unquote('"\\e9ef"');
$ic-angle-down: unquote('"\\e9f0"');
$ic-angle-double-up: unquote('"\\e9f1"');
$ic-angle-double-right: unquote('"\\e9f2"');
$ic-angle-double-left: unquote('"\\e9f3"');
$ic-angle-double-down: unquote('"\\e9f4"');
$ic-add-circle: unquote('"\\e9f5"');
