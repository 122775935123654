$dark-primary:    #31353d;
$dark-secondary:  #1c1d21;
$accent-secondary: #92cdcf;
$old-accent-tertiary: #d06a52;
$light-primary:   #eeeff7;
$royal-blue:      #2272b9;

$mid-blue:        #179fb1;
$dark-navy-blue:  #374760;
$light-navy-blue: #dddede;
$light-gray:      #ececec;
$lighter-gray:    #f8f8f8;
$mid-gray:        #ccc;
$dark-gray:       #9c9c9c;
$darker-gray:     #666;
$bluish-gray:     #9ba3b0;

$mid-yellow:      #f1c844;
$warning:         #fff7c7;
$orange:          #ee5b34;
$green:           #06b745;
$green-light:     #dcffea;
$light-blue:      #4079d9;
$lighter-blue:    #289adc;
$text-disabled:   #bbbbbb;

$header-black:    #090909;

$footer-text:     #485f7d;
$footer-link:     #ffffff;
$red:             #dd4b39;
$microsoft-blue:  #1db0eb;
$purple:          #5d2e88;
$slack-green:     #38ba91;
$aqua-green:      #55ae56;
$green-lighter:   #6db08d;
$section-blue:    #0a1c4b;
$section-blue-highlight: #a2cdff;
$maroon:          #570506;

// New design colours August 2018
$new-purple:      #6d51c5;
$new-purple-light: #7c5dd9;
$new-purple-lightest: #e4def7;
$new-blue:        #2e81de;
$new-blue-light:  #6ca7e8;
$new-blue-lighter:  #e7eff9;
$new-orange:      #fc932e;
$new-orange-light: #fdb26b;
$new-green:       #0eac83;
$new-green-light: #56c5a8;
$new-red:         #ee5253;
$new-red-light:   #f38686;
$new-red-lightest:#fcece9;
$new-yellow:      #feca57;
$new-yellow-light: #feda89;
$new-navy-light:  #607185;
$new-light-gray:  #e8eef4;
$new-mid-gray:    #dae2e9;
$new-dark-gray:   #a8b5c1;
$new-darker-gray: #8396a7;

