@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_center-block.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_hide-text.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_forms.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_vendor-prefixes.scss';
          @import 'app/assets/stylesheets/variables/_media_queries.scss';
          @import 'app/assets/stylesheets/variables/_old_colours.scss';
          @import 'app/assets/stylesheets/variables/_colours.scss';
          @import 'app/assets/stylesheets/variables/_icon_definitions.scss';
          @import 'app/assets/stylesheets/components/_item_search.scss';
          @import 'app/assets/stylesheets/components/search/_search_input.scss';

.icon {
  height: 100%;
  position: relative;
  font-size: 12px;
  // Left in case needed in the future
  // color: $gray;
}

.ic--success {
  color: $green;
}

.ic--warning {
  color: $new-orange-light;
}

.ic--danger {
  color: $orange;
}

.ic--muted {
  color: $cool-grey;
}

.ic--small {
  font-size: 80%;
}

.ic--medium {
  font-size: 100%;
}

.ic--large {
  font-size: 120%;
}

.ic--x-large {
  font-size: 140%;
}

.ic--xx-large {
  font-size: 30px;
}

.ic--bold {
  display: inline-block;
  font-weight: bold;
  transform: scaleY(0.6);
}

.ic--inverted {
  color: $white;
}

.ic--align-middle {
  top: 0;
}

// Icon specific overrides

// The cloud icon is poorly balanced compared to others
.ic-cloud-upload {
  top: 1px;
}
